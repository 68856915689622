import React, { useState, useEffect } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { storage } from "../config/firebaseConfig";
import { ref, listAll, getDownloadURL } from "firebase/storage";

const SubGallery = (props) => {
  const [images, setImages] = useState([]);
  const [folderImages, setFolderImages] = useState([]);
  const [index, setIndex] = useState(-1);

  const currentImage = index >= 0 ? folderImages[index] : null;
  const nextIndex = index >= 0 ? (index + 1) % folderImages.length : null;
  const nextImage = nextIndex !== null ? folderImages[nextIndex] : null;
  const prevIndex = index >= 0 ? (index + folderImages.length - 1) % folderImages.length : null;
  const prevImage = prevIndex !== null ? folderImages[prevIndex] : null;

  // Hide background content from screen readers when Lightbox is open
  useEffect(() => {
    const appContent = document.getElementById("page-top"); // Ensure your app content has an ID
    if (appContent) {
      appContent.setAttribute("aria-hidden", index >= 0 ? "true" : "false");
    }
    return () => {
      if (appContent) {
        appContent.setAttribute("aria-hidden", "false");
      }
    };
  }, [index]);

  const fetchImages = async (item) => {
    const storageRef = ref(storage, `rc/gallery/${item.path}`);
    try {
      const result = await listAll(storageRef);
      const urls = await Promise.all(
        result.items.map(async (i) => {
          const url = await getDownloadURL(i);
          return {
            src: url,
            original: url,
            name:i.name,
          };
        })
      );
      const thumbnail = urls.filter(itemRef => itemRef.name === item.name);
      const filteredArray = urls.filter(itemRef => itemRef.name !== item.name);
      const reUrls = [...thumbnail, ...filteredArray];
      setFolderImages(reUrls);
    } catch (error) {
      // console.error("Error fetching images:", error);
    }
  };

  const handleClick = async (clickedIndex, item) => {
    await fetchImages(item);
    setIndex(0);
  };

  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    if (props.data) {
      const fetchImageUrls = async () => {
        const gImages = [];
        await Promise.all(
          props.data.map(async (d) => {
            if (d.name) {
              try {
                const imageRef = ref(storage, `rc/gallery/${d.path}/${d.name}`);
                const url = await getDownloadURL(imageRef);
                gImages.push({
                  tags: [{ value: d.title, title: d.title }],
                  src: url,
                  original: url,
                  width: 320,
                  height: 174,
                  path: d.path,
                  name: imageRef.name,
                });
              } catch (error) {
                console.error("Error fetching image URL:", error);
              }
            }
          })
        );
        setImages(gImages);
        // if (gImages.length > 0) await fetchImages(gImages[0]);
      };
      fetchImageUrls();
    }
  }, [props.data]);

  return (
    <div id="app-content"> {/* Add an ID to your main app content */}
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption || ''}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage?.original}
          nextSrcThumbnail={nextImage?.src}
          prevSrc={prevImage?.original}
          prevSrcThumbnail={prevImage?.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  );
};

export default SubGallery;
