import React, { useState, useEffect } from "react";
import Footer from "./footer";

export const NoticeBoard = (props) => {
  const [notices, setNotices] = useState([]);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1024);

  useEffect(() => {
    props.data && setNotices(props.data);
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1024);
    };

    // Attach the resize listener
    window.addEventListener("resize", handleResize);

    // Clean up the listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props]);

  return (
    <>
      <div className="notice">
        <header className="notice-intro">
          <div className="intro-text">
            <h1>AOA Notice Board</h1>
          </div>
        </header>

        {/* Notice container to hold all the notices */}
        <div id="notices" className="notice-container">
          {notices.length > 0 ? (
            notices.map((notice, index) => (
              <div className="notice-item-v2" key={index}>
                <h3 dangerouslySetInnerHTML={{ __html: notice.title }} />
                {notice.content?.text && (
                  notice.content.showAsBullets ? (
                      <ul className="notice-item-text-list">
                        {notice.content.text.map((point, index) => (
                          <li key={index} className="mb-2">
                            {point}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="notice-item-text">{notice.content.text.join(" ")}</p>
                    )
                )}
                {/* Check if the content contains events */}
                {Array.isArray(notice.content?.events) ? (
                  <ul>
                    {notice.content.events.map((event, eventIndex) => (
                      <li key={eventIndex}>
                        <p>
                          <strong>{event.eventName}</strong>
                          {/* Nested activities list */}
                          {event.activities ? (
                            <ul>
                              {event.activities.map(
                                (activity, activityIndex) => (
                                  <li key={activityIndex}>{activity}</li>
                                )
                              )}
                            </ul>
                          ) : (
                            <br />
                          )}
                          <strong>Time: {event.time}</strong>
                          <br />
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : notice.content?.link?.url ? (
                  <a
                    href={notice.content.link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>{notice.content.link.text}</strong>
                  </a>
                ) : (
                  <p>{notice.content}</p>
                )}
              </div>
            ))
          ) : (
            <p>No notices available at the moment.</p>
          )}
        </div>
      </div>
      {isLargeScreen && (<Footer />)}
    </>
  );
};


// "url": "https://firebasestorage.googleapis.com/v0/b/rustlecourt-3d109.appspot.com/o/docs%2FAOA%206%20Month%20Report%202024.pdf?alt=media&token=26b8ca62-8876-40d2-825b-351810705beb"