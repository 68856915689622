import React from "react";
import SubGallery from "./SubGallery";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data ? (
              <SubGallery {...props} />
            ) : (
              "Loading..."
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
