// src/components/Slideshow.js
import React, { useEffect, useState } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../config/firebaseConfig";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Slideshow = (props) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const storageRef = ref(storage, "rc/slide-show/"); // Specify your folder path here
      try {
        const result = await listAll(storageRef);
        const urls = await Promise.all(
          result.items.map((item) => getDownloadURL(item))
        );
        setImages(urls);
      } catch (error) {
        //console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const getImages = () => {
    //console.log(images);
    return images.map((url, index) => (
      <div className="item" key={`img-${index}`}>
        <img className="ss-item" src={url} alt={`Slide ${index}`} />
      </div>
    ));
  };

  return images.length > 0 ? (
    props.data && (
      <Carousel autoPlay infiniteLoop showThumbs={false}>
        {getImages()}
      </Carousel>
    )
  ) : (
    <p>Loading...</p>
  );
};

export default Slideshow;
